'use strict';

import Actions from '../actions';
import React from 'react';
import { Link } from 'react-router-dom';
import Store from './store';




class LogoutPage extends React.Component {
    constructor(props) {

        super(props);

        this.input = {};
        this.state = Store.getState();
    }

    componentDidMount() {

        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

        Actions.logout();
    }

    componentWillUnmount() {

        this.unsubscribeStore();
    }

    onStoreChange() {

        this.setState(Store.getState());
    }

    render() {

        const alerts = [];

        if (this.state.success) {
            alerts.push(<div key="success" className="alert alert-success">
                Logout successful.
            </div>);
        }
        else if (this.state.error) {
            alerts.push(<div key="danger" className="alert alert-warning">
                {this.state.error}
            </div>);
        }

        return (
            <section>
                <h3 className="page-header">Sign out</h3>
                {alerts}
                <Link to="/login" className="btn btn-link no-padding">Back to login</Link>
            </section>
        );
    }
}


export default LogoutPage;
