'use strict';

import ClassNames from 'classnames';
import ObjectAssign from 'object-assign';
import React from 'react';
import PropTypes from 'prop-types';


const propTypes = {
    children: PropTypes.node,
    groupClasses: PropTypes.object,
    groupStyles: PropTypes.object,
    hasError: PropTypes.bool,
    help: PropTypes.any,
    helpClasses: PropTypes.object,
    hideHelp: PropTypes.bool,
    hideLabel: PropTypes.bool,
    label: PropTypes.string,
    labelClasses: PropTypes.object,
    nodeTopRight: PropTypes.node,
    labelClickTouch: PropTypes.func
};


class ControlGroup extends React.Component {

    labelClick(event){
        if ( this.props.labelClickTouch) {
            event.stopPropagation();
            event.preventDefault();
            this.props.labelClickTouch(event);
        }
        //console.log('label click');
    };
    render() {

        const groupClasses = ClassNames(ObjectAssign({
            'form-group': true,
            'has-error': this.props.hasError
        }, this.props.groupClasses));

        const labelClasses = ClassNames(ObjectAssign({
            'control-label': true,
            'form-control-placeholder': true
        }, this.props.labelClasses));

        const helpClasses = ClassNames(ObjectAssign({
            'help-block': true
        }, this.props.helpClasses));

        let label;

        if (!this.props.hideLabel && this.props.label !== '') {
            label = <label onTouchEnd={this.labelClick.bind(this)} onClick={this.labelClick.bind(this)} className={labelClasses}>
                {this.props.label}
            </label>;
        }

        let help;

        if (!this.props.hideHelp) {
            help = <span className={helpClasses}>
                {this.props.help}
            </span>;
        }

        return (
            <div className={groupClasses} style={this.props.groupStyles}>
                <div className='text-right'>{ this.props.nodeTopRight }</div>
                <div style={{ position: 'relative' }}>
                    {this.props.children} {label}
                    {help}
                </div>
            </div>
        );
    }
}

ControlGroup.propTypes = propTypes;


export default ControlGroup;
