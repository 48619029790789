'use strict';
import Actions from '../actions';
import Button from '../../../components/form/button.jsx';
import ControlGroup from '../../../components/form/control-group.jsx';
import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/form/spinner.jsx';
import Store from './store';
import TextControl from '../../../components/form/text-control.jsx';

class FinishCreatingAccountPage extends React.Component {
    constructor(props) {

        super(props);

        this.input = {};
        this.state = Store.getState();
    }

    componentDidMount() {

        Actions.resetFinishCreatingAccount(Store);
        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

        if (this.input.email) {
            this.input.email.focus();
        }
    }

    componentWillUnmount() {

        Actions.resetFinishCreatingAccount(Store);
        this.unsubscribeStore();
    }

    onStoreChange() {

        this.setState(Store.getState());
    }

    handleSubmit(event) {

        event.preventDefault();
        event.stopPropagation();

        Actions.finishCreatingAccount({
            email: this.input.email.value().trim()
        }, Store);
    }

    render() {

        const alerts = [];

        if (this.state.success) {
            alerts.push(<div key="success">
                <div className="alert alert-success">
                    If an account matched that address, an email will be sent with instructions within 10 minutes.
                </div>
                <Link to="/login" className="btn btn-link no-padding">Back to login</Link>
            </div>);
        }

        if (this.state.error) {
            alerts.push(<div key="danger" className="alert alert-danger">
                {this.state.error}
            </div>);
        }

        let formElements;

        if (!this.state.success) {
            formElements = <fieldset>
                <TextControl
                    ref={(c) => (this.input.email = c)}
                    name="email"
                    label="What's your email?"
                    hasError={this.state.hasError.email}
                    help={this.state.help.email}
                    disabled={this.state.loading}
                />
                <ControlGroup hideLabel={true} hideHelp={true}>
                    <Button
                        type="submit"
                        inputClasses={{ 'btn-primary': true }}
                        disabled={this.state.loading}>

                        Send instructions
                        <Spinner space="left" show={this.state.loading} />
                    </Button>
                    <Link to="/login" className="btn btn-link">Back to login</Link>
                </ControlGroup>
            </fieldset>;
        }

        return (
            <section>
                <h3 className="page-header">Finish creating your account?</h3>                
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {alerts}
                    {formElements}
                </form>
            </section>
        );
    }
}


export default FinishCreatingAccountPage;
