'use strict';
import ClassNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';


const propTypes = {
    show: PropTypes.bool,
    space: PropTypes.string,
    className: PropTypes.string
};


class Spinner extends React.Component {
    render() {

        let spaceLeft;

        if (this.props.space === 'left') {
            spaceLeft = '\u00A0\u00A0';
        }

        let spaceRight;

        if (this.props.space === 'right') {
            spaceRight = '\u00A0\u00A0';
        }

        const spinnerClasses = ClassNames({
            hidden: !this.props.show
        });

        return (
            <span className={spinnerClasses + ' ' + this.props.className}>
                {spaceLeft}
                <i className="fa fa-spinner fa-spin"></i>
                {spaceRight}
            </span>
        );
    }
}

Spinner.propTypes = propTypes;


export default Spinner;
