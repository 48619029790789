/* global document */

import Modal from './modal.jsx';
import ControlGroup from './form/control-group.jsx';
import React from 'react';
import PropTypes from 'prop-types';


const propTypes = {
    message: PropTypes.string
};



class CrumbCheckPage extends React.Component {
    constructor(props) {

        super(props);
    }

    getCrumb() {

        return document.cookie.split(';').filter((item) => {
            return item.trim().indexOf('crumb=') === 0;
        });
    }

    render() {

        //this was some business to send the old crumbs up to the server
        //when I was debugging something
        //if ( this.state.err && this.state.err.statusCode === 403) {

        /*not sure this will do any good, if crumb was missing then it would get replaced on request reply.
            const crumbs = this.getCrumb();
            if (crumbs.length > 0) {
                document.cookie = 'crumb-old=' + crumbs.map((c) => {
                    return c.substring('crumb='.length + 1);
                }).join(' ');
                document.cookie = 'crumb=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }*/

        /*
            alerts.push(<div key="danger" className="alert alert-danger">
                {this.state.error}.  Invalid CSRF Cookie. Please try again.
            </div>);
            */
        //showModal = true;
        //showContentBlockAdvisory = true;
        //}

        //const showModal = document.cookie === '';// work around to Apple ITP disabling cookies after email click or redirect
        const showModal = this.getCrumb().length === 0;// work around to Apple ITP disabling cookies after email click or redirect
        let showContentBlockAdvisory = false;

        const search = document.location.search;
        let href = document.location.href;
        if ( showModal ) {
            if ( search === '') {
                href = href + '?cb=1';//cb for content blocking
            }
            else  if ( search.indexOf('?cb=') < 0 && search.indexOf('&cb=')){
                href = href + '&cb=1';
            }
            else {
                //content is being blocked.
                showContentBlockAdvisory = true;
            }
        }

        const content_block_advisory = (
            <div>
                <h4>Content Blocked</h4>
                <p>It appears that this browser is looking out for your privacy a little too aggressively.
                </p>
                <p>We need to set a cookie for log on to work correctly but this browser is blocking it.</p>
                <p>This can be remedied by changing this browser's settings or more easily by simply copying the URL
                    for this page, opening a new tab and typing in www.prioracare.com, and then pasting
                    the URL for this page into the URL bar for the new tab.</p>
            </div>
        );

        return (
            <Modal
                header={'Welcome!'}
                show={showModal}
            >
                <div>
                    {showContentBlockAdvisory && content_block_advisory}
                    <p>{this.props.message}</p>
                    <ControlGroup hideLabel={true} hideHelp={true}>
                        <div className="btn-toolbar" >
                            <a href={href} className='btn btn-primary min-width'>Continue</a>
                        </div>
                    </ControlGroup>
                </div>
            </Modal>
        );
    }
}

CrumbCheckPage.propTypes = propTypes;

export default CrumbCheckPage;
