'use strict';
import FluxConstant from 'flux-constant';


export default FluxConstant.set([
    'GET_CONFIRM',
    'GET_CONFIRM_RESPONSE',
    'CONFIRM',
    'CONFIRM_RESPONSE'
]);
