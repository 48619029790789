/* global window */
'use strict';

import ApiActions from '../../../actions/api';
import Constants from './constants';
import Store from './store';


class Actions {

    static getDetails(user_id, key) {

        ApiActions.get(
            `/api/login/confirm/${user_id}/${key}`,
            {},
            Store,
            Constants.GET_CONFIRM,
            Constants.GET_CONFIRM_RESPONSE
        );
    }
    static confirm(data) {

        ApiActions.post(
            '/api/login/confirm',
            data,
            Store,
            Constants.CONFIRM,
            Constants.CONFIRM_RESPONSE
            /*
            (err, response) => {

                if (!err) {
                    window.location.href = '/login';
                }
            }*/
        );
    }
}


export default Actions;
