/* global window */
'use strict';

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../home/cart/cart.jsx'; //front facing pages don't have a module that runs but login does so we include
import Confirm from './confirm/index.jsx';
import CreatePassword from './create-password';
import FinishCreatingAccountPage from './finish-creating-account';
import Forgot from './forgot/index.jsx';
import Home from './home/index.jsx';
import Logout from './logout/index.jsx';
import NotFound from './not-found.jsx';
import Reset from './reset/index.jsx';
import SendLoginLink from './send-login-link';
//cart here in the module.  other wise the shared dependencies run twice

const ssoRedirect = window && window.__ssoRedirect;
const App = (
    <Router>
        <Switch>
            <Route exact path="/login" render={(props) => <Home {...props} ssoRedirect={ssoRedirect} />} />
            <Route path="/login/forgot" component={Forgot} />
            <Route path="/login/finish-creating-account" component={FinishCreatingAccountPage} />
            <Route path="/login/send-login-link" component={SendLoginLink} />
            <Route path="/login/create-password/:id/:key/:username" component={CreatePassword} />
            <Route path="/login/confirm/:id/:key" component={Confirm} />
            <Route path="/login/reset/:id/:key" component={Reset} />
            <Route path="/login/logout" component={Logout} />
            <Route component={NotFound} />
        </Switch>
    </Router>

);

export default App;
