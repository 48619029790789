/* global document */
'use strict';

import Actions from '../actions';
import Button from '../../../components/form/button.jsx';
import ControlGroup from '../../../components/form/control-group.jsx';
import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/form/spinner.jsx';
import Store from './store';
import TextControl from '../../../components/form/text-control.jsx';
import ConfirmStore from '../confirm/store';
import ObjectAssign from 'object-assign';
import CrumbCheck from '../../../components/crumb-check.jsx';
import PropTypes from 'prop-types';
import Qs from 'qs';

const propTypes = {
    ssoRedirect: PropTypes.string
};

class LoginHome extends React.Component {
    constructor(props) {

        super(props);

        const query = Qs.parse(this.props.location.search.substring(1));

        this.input = {};
        this.state = ObjectAssign({}, Store.getState(), { username: '', showNonEmployeeSignIn: false, loginToken: query.loginToken, loginError: '' });
    }

    componentDidMount() {
        const { loginToken } = this.state;
        const urlParams = new URLSearchParams(window.location.search);
        const returnUrl = urlParams.get('returnUrl');
        if (returnUrl) {
            localStorage.setItem('returnUrl', returnUrl);
        }

        if (loginToken) {
            Actions.loginWithToken({ loginToken, stay: true }, Store, (err, response) => {
                if (err) {
                    this.setState({ loginError: response.message });
                }
                else {
                    window.location.href = '/account/clients';
                }
            })
            return;
        }

        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

        const confirm = ConfirmStore.getState().confirm;
        if (confirm.confirmed_email) {
            this.setState({
                username: confirm.confirmed_email
            });
            if (this.input.password) {
                this.input.password.focus();
            }
        }
        else {
            if (this.input.username) {
                this.input.username.focus();
            }
        }
    }

    componentWillUnmount() {

        this.unsubscribeStore();
    }


    onStoreChange() {

        this.setState(Store.getState());
    }

    handleSubmit(event) {

        event.preventDefault();
        event.stopPropagation();

        Actions.login({
            //trim is a workaround.  for some reason android is saving bad logins
            //and spaces are not seeable when saved.  This is an email and email's can't have
            //spaces, perhaps this should be server side but whatever
            username: this.input.username.value().trim(),
            password: this.input.password.value(),
            stay: this.input.stay.checked
        });
    }

    getCrumb() {

        return document.cookie.split(';').filter((item) => {
            return item.trim().indexOf('crumb=') === 0;
        });
    }

    toggleNonEmployeeSignIn() {
        this.setState({
            showNonEmployeeSignIn: !this.state.showNonEmployeeSignIn
        });
    }

    render() {
        const { showNonEmployeeSignIn, loginToken, loginError } = this.state;

        if (loginToken) {
            return (
                <div className='container-fluid' style={{ marginTop: '40px' }}>
                    <div className='text-center'>
                        {loginError ? (
                            <p>{loginError}</p>
                        ) : (
                                <>
                                    <p>Logging in...</p>
                                    <Spinner space="left" show={true} />
                                </>
                            )}
                    </div>
                </div>

            );
        }



        const alerts = [];

        if (this.state.success) {
            alerts.push(<div key="success" className="alert alert-success">
                Success. Redirecting...
            </div>);
        }

        if (this.state.error) {

            alerts.push(<div key="danger" className="alert alert-danger">
                {this.state.error}
            </div>);
        }

        let formElements;

        if (!this.state.success) {
            formElements = <fieldset>
                <TextControl
                    ref={(c) => (this.input.username = c)}
                    value={this.state.username}
                    onChange={(e) => {
                        this.setState({
                            username: e.target.value.trim() //android auto complete adds a space and people don't see it
                        });
                    }}
                    name="username"
                    label="Email"
                    autoComplete="username email"
                    hasError={this.state.hasError.username}
                    help={this.state.help.username}
                    disabled={this.state.loading}
                />
                <TextControl
                    ref={(c) => (this.input.password = c)}
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    hasError={this.state.hasError.password}
                    help={this.state.help.password}
                    disabled={this.state.loading}
                    onChange={(e) => { }}
                />
                <div className="form-group inline-checkbox">

                    <label
                        htmlFor={this.input.stay}
                        className="inline-checkbox text-muted"
                    ><input name='stay'
                        className="inline-checkbox"
                        type='checkbox'
                        defaultChecked
                        ref={(c) => (this.input.stay = c)}
                        />
                        Stay signed in</label>
                </div>
                <ControlGroup hideLabel={true} hideHelp={true}>
                    <Button
                        type="submit"
                        inputClasses={{ 'btn-primary': true, signInBtn: true }}
                        disabled={this.state.loading}>
                        Sign in
                        <Spinner space="left" show={this.state.loading} />
                    </Button>
                    <h4 className=""></h4>
                    <div style={{ marginLeft: '-12px', marginTop: '12px' }}>
                        <Link to="/login/forgot" className="btn btn-link">Forgot your password?</Link>
                        <Link to="/login/finish-creating-account" className="btn btn-link">Finish creating your account?</Link>
                        <Link to="/login/send-login-link" className="btn btn-link">Send login link?</Link>
                    </div>
                </ControlGroup>
            </fieldset>;
        }

        const sso = process.env.ENABLE_SSO;

        return (
            <div>
                <div className='container-fluid' style={{ marginTop: '40px' }} >
                    <div className='row' >

                        {sso &&
                            <div className='col-sm-6 text-center' >
                                <h4 className="page-header text-muted">Employees</h4>
                                <ControlGroup hideLabel={true} hideHelp={true}>
                                    <a className='btn btn-primary signInBtn' href={this.props.ssoRedirect}>
                                        Sign in
                                </a>
                                </ControlGroup>
                            </div>
                        }
                        <div className='col-sm-6 text-center' style={{ borderLeft: '2px solid #eee', paddingLeft: '24px' }} >
                            <h4 className="page-header text-muted">{sso ? 'Non-employees' : 'Sign in'}</h4>
                            <ControlGroup hideLabel={true} hideHelp={true}>
                                {!showNonEmployeeSignIn && sso && (
                                    <a className='btn btn-primary signInBtn' onClick={(e) => {
                                        e.preventDefault();
                                        this.toggleNonEmployeeSignIn();
                                        return false;
                                    }}>
                                        Sign in
                                    </a>
                                )}
                            </ControlGroup>
                            {(showNonEmployeeSignIn || !sso) && (
                                <form onSubmit={this.handleSubmit.bind(this)} className='text-left'>
                                    {alerts}
                                    {formElements}
                                </form>
                            )}
                            <CrumbCheck message={'Please click below to continue with the registration process.'} />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

LoginHome.propTypes = propTypes;

export default LoginHome;
