'use strict';

import Constants from '../constants';
import ObjectAssign from 'object-assign';
import ParseValidation from '../../../../helpers/parse-validation';


const initialState = {
    hydrated: false,
    loading: false,
    error: undefined,
    hasError: {},
    help: {},
    alreadySetup: false,
    expired: false,
    valid: false,
    confirmed: false,
    success: false,
    email: '',
    confirmed_email: ''
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.CONFIRM) {
        return ObjectAssign({}, state, {
            loading: true
        });
    }

    if (action.type === Constants.CONFIRM_RESPONSE) {

        const validation = ParseValidation(action.response);

        return ObjectAssign({}, state, {
            loading: false,
            success: !action.err,
            error: validation.error,
            confirmed_email: action.response.email,
            hasError: validation.hasError,
            help: validation.help
        });
    }

    if (action.type === Constants.GET_CONFIRM) {
        return ObjectAssign({}, state, {
            hydrated: false,
            loading: true
        });
    }

    if (action.type === Constants.GET_CONFIRM_RESPONSE) {
        const validation = ParseValidation(action.response);

        if ( !validation.error ){

            return ObjectAssign({}, state, {
                hydrated: true,
                loading: false,
                valid: true,
                email: action.response.email//we use this give the IOS key chain auto a user name to remember us by
            });
        }

        const hasError = {};
        const help = {};
        let expired = false;
        let alreadySetup = false;
        hasError.key = true;
        if ( action.response.statusCode === 404 ){

            alreadySetup = true;
            help.key = 'Account already set up';
        }
        else if ( action.response.statusCode === 410 ){
            expired = true;
            help.key = 'Welome link expired.';
        }

        return ObjectAssign({}, state, {

            hydrated: true,
            loading: false,
            error: true,
            alreadySetup,
            expired,
            help,
            hasError,
            valid: false
        });

    }

    return state;
};


export default reducer;
