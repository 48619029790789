'use strict';

import * as Redux from 'redux';
import Logout from './reducers/logout.js';
import SwitchAccounts from './reducers/switch-accounts.js';
import AddOAccount from './reducers/add-oaccount.js';

export default Redux.createStore(
    Redux.combineReducers({
        logout: Logout,
        switchAccounts: SwitchAccounts,
        addOAccount: AddOAccount
    })
);
