'use strict';
import Constants from '../constants';
import ObjectAssign from 'object-assign';
import ParseValidation from '../../../helpers/parse-validation';
import * as Redux from 'redux';


const initialState = {
    loading: false,
    success: false,
    error: undefined,
    hasError: {},
    help: {}
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.RESET) {
        return ObjectAssign({}, state, {
            loading: true
        });
    }

    if (action.type === Constants.RESET_RESPONSE) {
        const validation = ParseValidation(action.response);

        return ObjectAssign({}, state, {
            loading: false,
            success: !action.err,
            error: validation.error,
            hasError: validation.hasError,
            help: validation.help
        });
    }

    return state;
};


export default Redux.createStore(reducer);
