/* global window */
'use strict';

import ApiActions from '../../actions/api';
import Constants from './constants';
import ForgotStore from './forgot/store';
import FinishCreatingAccountStore from './finish-creating-account/store';
import LoginStore from './home/store';
import LogoutStore from './logout/store';
import Qs from 'qs';
import ResetStore from './reset/store';
import Log from '../../helpers/log.js';


class Actions {

    static resetLogin(store = LoginStore){

        //get eror messages out of store
        store.dispatch({
            type: Constants.LOGIN_CLEAR
        });
    }

    static resetForgot(forgotStore = ForgotStore) {

        forgotStore.dispatch({
            type: Constants.FORGOT_RESET
        });
    }
    static forgot(data, forgotStore = ForgotStore) {

        ApiActions.post(
            '/api/login/forgot',
            data,
            forgotStore,
            Constants.FORGOT,
            Constants.FORGOT_RESPONSE
        );
    }

    static resetFinishCreatingAccount(store = FinishCreatingAccountStore) {

        store.dispatch({
            type: Constants.FINISH_CREATING_ACCOUNT_RESET
        });
    }
    static finishCreatingAccount(data, store = FinishCreatingAccountStore) {

        ApiActions.post(
            '/api/login/finish-creating-account',
            data,
            store,
            Constants.FINISH_CREATING_ACCOUNT,
            Constants.FINISH_CREATING_ACCOUNT_RESPONSE
        );
    }

    static resetSendLoginLink(store = SendLoginLinkStore) {

        store.dispatch({
            type: Constants.SEND_LOGIN_LINK_RESET
        });
    }
    static sendLoginLink(data, store = SendLoginLinkStore) {

        ApiActions.post(
            '/api/login/send-login-link',
            data,
            store,
            Constants.SEND_LOGIN_LINK,
            Constants.SEND_LOGIN_LINK_RESPONSE
        );
    }

    static loginWithToken(data, store = LoginStore, cb) {

        ApiActions.post(
            '/api/login-with-token',
            data,
            store,
            Constants.LOGIN_WITH_TOKEN,
            Constants.LOGIN_WITH_TOKEN_RESPONSE,
            cb
        );
    }

    static login(data, loginStore = LoginStore, cb) {

        ApiActions.post(
            '/api/login',
            data,
            loginStore,
            Constants.LOGIN,
            Constants.LOGIN_RESPONSE,
            (err, response) => {

                if (err ) {
                    setTimeout(() => {
                        //timeout required because crumb cookies can be switching around
                        Log('Error Signon', { username: data.username, message: err.message, response }, undefined, undefined, true);
                    }, 1);
                }

                if ( cb ) {
                    cb(err, response);
                }
                else if (!err) {
                    const query = Qs.parse(window.location.search.substring(1));

                    const roles = response.user.roles;
                    if (query.returnUrl) {
                        window.location.href = query.returnUrl;
                    }
                    else if (roles.root) {
                        window.location.href = '/admin/organizations';
                    }
                    else if (roles.oadmin) {
                        window.location.href = `/admin/organizations/${roles.oadmin.organization_id}`;
                    }
                    else {
                        window.location.href = '/account/clients';

                        // const isBehaviorFrontierEmployee = data.username.indexOf('@behaviorfrontiers.com') > -1;
                        // if (isBehaviorFrontierEmployee) {
                        //     window.location.href = '/account/dash/bt-top';
                        // }
                        // else {
                        //     window.location.href = '/account/clients';
                        // }
                    }
                }
            }
        );
    }

    static logout(cb, logoutStore = LogoutStore) {

        ApiActions.delete(
            '/api/logout',
            undefined,
            logoutStore,
            Constants.LOGOUT,
            Constants.LOGOUT_RESPONSE,
            cb
        );
    }

    static addOAccount(oId, cb, logoutStore = LogoutStore) {

        ApiActions.post(
            `/api/organizations/${oId}/oaccount`,
            {},
            logoutStore,
            Constants.ADD_OACCOUNT,
            Constants.ADD_OACCOUNT_RESPONSE,
            cb
        );
    }

    static switchAccount(type, type_id, organization_id, loginStore = LoginStore, cb) {

        ApiActions.post(
            '/api/login/switch',
            { type, type_id },
            loginStore,
            Constants.SWITCH_ACCOUNTS,
            Constants.SWITCH_ACCOUNTS_RESPONSE,
            ( err ) => {

                if ( !err ){

                    if ( cb ){
                        cb();
                    } else if ( type === 'oadmin') {
                        location = `/admin/organizations/${organization_id}`;
                    }
                    else if ( type === 'oaccount') {
                        location = '/account';
                    }
                } else {
                    if (cb) {
                        cb(err);
                    }
                }
            }
        );
    }

    static reset(data, cb) {
        ApiActions.post(
            '/api/login/reset',
            data,
            ResetStore,
            Constants.RESET,
            Constants.RESET_RESPONSE,
            cb
        );
    }
}


export default Actions;
