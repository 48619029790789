'use strict';

import Actions from '../actions';
import Button from '../../../components/form/button.jsx';
import ControlGroup from '../../../components/form/control-group.jsx';
import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/form/spinner.jsx';
import Store from './store';
import TextControl from '../../../components/form/text-control.jsx';
import PropTypes from 'prop-types';
import CrumbCheck from '../../../components/crumb-check.jsx';


const propTypes = {
    match: PropTypes.object
};


class ResetPage extends React.Component {
    constructor(props) {

        super(props);

        this.input = {};
        this.state = Store.getState();
    }

    componentDidMount() {

        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

        if (this.input.password) {
            this.input.password.focus();
        }
    }

    componentWillUnmount() {

        this.unsubscribeStore();
    }

    onStoreChange() {

        this.setState(Store.getState());
    }

    handleSubmit(event) {

        event.preventDefault();
        event.stopPropagation();

        Actions.reset({
            id: this.props.match.params.id,
            key: this.props.match.params.key,
            password: this.input.password.value()
        });
    }

    render() {

        const alerts = [];

        if (this.state.success) {
            alerts.push(<div key="success">
                <div className="alert alert-success">
                    Your password has been reset. Please login to confirm.
                </div> <Link to="/login" className="btn btn-link no-padding">Back to login</Link>
            </div>);
        }

        if (this.state.error) {
            alerts.push(<div key="danger" className="alert alert-danger">
                {this.state.error}
            </div>);
        }

        let formElements;

        if (!this.state.success) {
            formElements = <fieldset>
                <TextControl
                    ref={(c) => (this.input.password = c)}
                    name="password"
                    label="New password"
                    type="password"
                    hasError={this.state.hasError.password}
                    help={this.state.help.password}
                    disabled={this.state.loading}
                />
                <h5>* Password must be at least 8 characters and include 1 character from 3 of the following: Upper case, lower case, numeric, non alphanumeric.</h5>
                <ControlGroup hideLabel={true} hideHelp={true}>
                    <Button
                        type="submit"
                        inputClasses={{ 'btn': true, 'btn-primary': true }}
                        disabled={this.state.loading}>

                        Set password
                        <Spinner space="left" show={this.state.loading} />
                    </Button>
                    <Link to="/login" className="btn btn-link">Back to login</Link>
                </ControlGroup>
            </fieldset>;
        }

        return (
            <section>
                <h3 className="page-header">Reset your password</h3>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {alerts}
                    {formElements}
                </form>
                <CrumbCheck message={'Please click below to continue changing your password.'}/>
            </section>
        );
    }
}

ResetPage.propTypes = propTypes;


export default ResetPage;
