'use strict';

import Constants from '../../constants';
import ObjectAssign from 'object-assign';
import ParseValidation from '../../../../helpers/parse-validation';

const initialState = {
    type_id: undefined,
    loading: false,
    success: false,
    error: undefined,
    hasError: {},
    help: {}
};
const reducer = function (state = initialState, action) {

    if (action.type === Constants.ADD_OACCOUNT) {
        return ObjectAssign({}, state, {
            loading: true,
            type_id: action.request.data.type_id
        });
    }

    if (action.type === Constants.ADD_OACCOUNT_RESPONSE) {
        const validation = ParseValidation(action.response);

        return ObjectAssign({}, state, {
            loading: false,
            type_id: undefined,
            success: !action.err,
            error: validation.error,
            hasError: validation.hasError,
            help: validation.help
        });
    }

    return state;
};


export default reducer;
