/* global document, window */
'use strict';

import ClassNames from 'classnames';
import React from 'react';
import { v1 as uuidv1 } from 'uuid';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const propTypes = {
    children: PropTypes.node,
    footer: PropTypes.node,
    header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    subHeaders: PropTypes.array,
    logo: PropTypes.string,
    onClose: PropTypes.func,
    demandChoice: PropTypes.bool,
    preventBackDropClick: PropTypes.bool,
    show: PropTypes.bool,
    circleClose: PropTypes.bool,
    classes: PropTypes.array,
    bgClasses: PropTypes.array,
    innerClasses: PropTypes.array,
    headerClasses: PropTypes.array,
    bodyClasses: PropTypes.array,
    scrollToBottom: PropTypes.bool,
    scrollToTop: PropTypes.bool,
    leftClose: PropTypes.bool,
    buttons: PropTypes.array,
    addToBody: PropTypes.bool,
    hideClose: PropTypes.bool,
    disableEsc: PropTypes.bool,
};


class Modal extends React.Component {
    constructor(props) {

        super(props);

        this.els = {};
        let bgHeight = '100px';
        if ( global.window ) {
            bgHeight = window.innerHeight;
        }

        this.state = {
            bgHeight
        };
        this.boundWindowResize = this.onWindowResize.bind(this);
        this.boundKeyUp = this.onKeyUp.bind(this);
        this.uuid = 'modal--open--' + uuidv1();//don't ever use a class like modal--open-- somewhere else
        this.mouseDownTarget = null;
    }

    componentDidMount() {

        window.addEventListener('resize', this.boundWindowResize);

        if (this.els.modal && this.props.scrollToBottom) {
            setTimeout(() => {
                this.els.modal.scrollTo(0, this.els.modal.offsetHeight);
            }, 1);
        }

        if (this.els.modal && this.props.scrollToTop) {
            setTimeout(() => {
                this.els.modal.scrollTo(0, 0);
            }, 1);
        }

        if (this.props.show ) {
            this.showDialog(true);
        }
    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.boundWindowResize);
        document.removeEventListener('keyup', this.boundKeyUp);
        this.showDialog(false);
        //document.body.classList.remove('modal-open');
    }


    //bootstrap 3.3 doesn't support 2 modals that I know of.  What was happening to me was 'modal-open' needs to
    //on the body for the modal to scroll.  We putting4various modals on and off the code that was looking for a modal's
    //props to change from not showing to showing or showing to not showing we conflicting when it came to adding, leaving,
    //or removing modal-open.  So now add modal open and a unique id that others can see and know that some else may
    //want the modal open so they don't remove 'modal-open'.

    showDialog(show) {

        if ( show ) {
            //we may not need to check as classList may be a set and not an array, not sure.  I check to be safe

            //we need this and adding it twice doesn't matter as is overwrites itself anyway
            document.addEventListener('keyup', this.boundKeyUp);

            //we are going need 'modal-open'  We if not there we put it there
            //classList is array like so we use contains
            if ( !document.body.classList.contains('modal-open')) {
                document.body.classList.add('modal-open');
            }

            //now we add our uuid.
            if ( !document.body.classList.contains(this.uuid)){
                document.body.classList.add(this.uuid);//this is a marker so we know we added modal-open
            }
        }
        else {
            document.removeEventListener('keyup', this.boundKeyUp);
            document.body.classList.remove(this.uuid);//this is a our marker
            //check for others that want it open.  This can happen when opening one can closing another at the same time.
            if ( document.body.className.indexOf('modal--open--') < 0){
                document.body.classList.remove('modal-open');
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps.show && this.props.show) { //we are adding it

            this.showDialog(true);
            /*
            document.addEventListener('keyup', this.boundKeyUp);
            if ( !document.body.classList.contains('modal-open')){
                document.body.classList.add('modal-open');
            }

            document.body.classList.add(this.uuid);//this is a marker so that given modal says there are here and want modal-open
            //there is a case where 2 modals are coming and going in the same set state
            */
        }
        else if ( prevProps.show && !this.props.show ) { //we are removing it
            this.showDialog(false);
            /*
            document.removeEventListener('keyup', this.boundKeyUp);
            document.body.classList.remove(this.uuid);//this is a our marker
            //check for others that want it open.  This can happen when opening one can closing another at the same time.
            if ( document.body.className.indexOf('modal--open--') < 0){
                document.body.classList.remove('modal-open');
            }*/
        }
    }

    onWindowResize() {

        this.setState({ bgHeight: window.innerHeight });
    }

    onMouseDown(event) {//need this to detect if the click started inside the dialog and ended outside
        //we don't want the dialog ot be closed on a drag taht starts inside and ends outside like when letters
        //are highlighted and erased
        this.mouseDownTarget = event.target;
    }

    onBackdropClick(event) {

        if ( this.props.preventBackDropClick) {
            return;
        }

        if (event.target === event.currentTarget && this.mouseDownTarget === event.currentTarget) {
            this.props.onClose(event);
        }

        this.mouseDownTarget = null;
    }

    onKeyUp(event) {
        const { disableEsc = false, onClose } = this.props;
        if (!disableEsc && event.which === 27) {
            onClose();
        }
    }

    getInnerModalClasses() {
        const defaultClasses = [`modal-dialog`, this.props.circleClose ? 'modal-dialog-circle-close' : ''];
        const innerClasses = this.props.innerClasses
            ? defaultClasses.concat(this.props.innerClasses)
            : defaultClasses;
        return ClassNames(innerClasses);
    }

    getModalHeaderClasses() {
        const defaultClasses = ['modal-header'];
        const headerClasses = this.props.headerClasses
            ? defaultClasses.concat(this.props.headerClasses)
            : defaultClasses;
        return ClassNames(headerClasses);
    }
        
    getModalBodyClasses() {
        const defaultClasses = ['modal-body'];
        const bodyClasses = this.props.bodyClasses
            ? defaultClasses.concat(this.props.bodyClasses)
            : defaultClasses;
        return ClassNames(bodyClasses);
    }

    render() {

        const classNames = {
            modal: true
        };
        if ( this.props.classes ) {
            this.props.classes.forEach( (c) => {
                classNames[c] = true;
            });
        }

        const bgClassNames = {
            'modal-backdrop': true,
            'in': true
        };
        if ( this.props.bgClasses ) {
            this.props.bgClasses.forEach( (c) => {
                bgClassNames[c] = true;
            });
        }

        const modalClasses = ClassNames(classNames);
        const modalBgClasses = ClassNames(bgClassNames);
        const innerModalClasses = this.getInnerModalClasses();
        const modalHeaderClasses = this.getModalHeaderClasses();
        const modalBodyClasses = this.getModalBodyClasses();
        const modalStyles = {};
        const { leftClose, buttons, hideClose } = this.props;

        if (this.props.show) {
            modalStyles.display = 'block';
        }

        const modalBgStyles = {
            height: this.state.bgHeight + (this.state.bgHeight + '').endsWith('px') ? '' : 'px',
            top: '0px'
        };
        const containerStyles = { display: 'none' };

        if (this.props.show) {
            containerStyles.display = 'block';
        }

        let modalHeader;
        if (this.props.header || this.props.logo) {

            let logo = '';
            if ( this.props.logo ) {
                logo = ( <img className="modal-logo" src={this.props.logo} /> );
            }

            let button;
            if ( !this.props.demandChoice) {
                button = (
                    <button type="button" className="close" onClick={this.props.onClose}>
                        &times;
                    </button>
                );
            }

            let buttonsComponent = null;
            if (buttons) {
                buttonsComponent = (
                    <div className='buttons-wrapper'>
                        {buttons.map((item, itemIndex) => {
                            const { icon, disabled = false, loading = false, onClick, iconStyle = {}, custom = false, component = null } = item;

                            if (custom) {
                                return <div key={`modal-button-${itemIndex}`}>{component}</div>;
                            }

                            const _iconStyle = loading ? { position: "static" } : {}
                            return (
                                <button
                                    key={`modal-button-${itemIndex}`}
                                    onClick={onClick}
                                    className={`no-focus edit-button btn-header${
                                        !disabled ? "" : " disabled"
                                    }`}
                                    disabled={disabled}
                                >
                                    <i
                                        tabIndex={-1}
                                        className={`no-focus ${
                                            loading ? "fa fa-spinner fa-spin" : icon
                                        }`}
                                        style={{...iconStyle, ..._iconStyle}}
                                    ></i>
                                </button>
                            );
                        })}
                    </div>
                );
            }

            modalHeader = (
                <div className={modalHeaderClasses}>
                    {(leftClose && !hideClose) && button}
                    <h4 className="modal-title"> {logo} {this.props.header}</h4>
                    { this.props.subHeaders && this.props.subHeaders.map((subHeader, i) => {
                        return <div key={i}>{subHeader}</div>;
                    })}
                    {(!leftClose && !hideClose) && button}
                    {buttonsComponent}
                </div>
            );
        }
        else if ( this.props.circleClose) {

            modalHeader = (
                <div style={{ position: 'relative' }}>
                    <button type="button" className="circle-close"
                        onClick={this.props.onClose}>
                        <i className='fa fa-close'></i>
                    </button>
                </div>
            );
        }


        let modalFooter;

        if (this.props.footer) {
            modalFooter = <div className="modal-footer">
                {this.props.footer}
            </div>;
        }

        return (
            <div style={containerStyles}>
                <div
                    ref={(c) => (this.els.backdrop = c)}
                    className={modalBgClasses}
                    style={modalBgStyles}>
                </div>
                <div
                    id={this.props.id}
                    ref={(c) => (this.els.modal = c)}
                    style={modalStyles}
                    className={modalClasses}
                    onMouseDown={this.onMouseDown.bind(this)}
                    onClick={this.onBackdropClick.bind(this)}>

                    <div
                        ref={(c) => (this.els.dialog = c)}
                        className={innerModalClasses}>
                        <div className="modal-content">
                            {modalHeader}
                            <div className={modalBodyClasses} ref={current => {
                                if (current && current.parentNode && current.parentNode.firstElementChild && this.props.subHeaders) {
                                    current.style.marginTop = `${current.parentNode.firstElementChild.offsetHeight + 5}px`;
                                }
                            }}>
                                {this.props.children}
                            </div>
                            {modalFooter}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}




const ModalWrapper = (props) => {
    const { addToBody } = props;

    if (addToBody) {
        return createPortal(
            <Modal {...props} />,
            document.body,
        );
    }
    
    return <Modal {...props} />;
}

ModalWrapper.propTypes = propTypes;

export default ModalWrapper;
