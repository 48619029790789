'use strict';

import ClassNames from 'classnames';
import ControlGroup from './control-group.jsx';
import ObjectAssign from 'object-assign';
import React from 'react';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';


const propTypes = {
    labelClasses: PropTypes.object,
    hideLabel: PropTypes.bool,
    hideHelp: PropTypes.bool,
    autoCapitalize: PropTypes.string,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    help: PropTypes.string,
    inputClasses: PropTypes.object,
    groupClasses: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    readOnly: PropTypes.bool,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
    clearx: PropTypes.bool,
    textCleared: PropTypes.func,
    autoComplete: PropTypes.string,
    autoCompleted: PropTypes.func,
    autoValues: PropTypes.array,
    nodeTopRight: PropTypes.node,
    delaySaveTimeInMS: PropTypes.number,
    maxLength: PropTypes.number,
};
const defaultProps = {
    type: 'text',
    autoCapitalize: 'off',
    checked: false
};


class TextControl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            focused: false
        };
    }

    blur() {

        return this.input.blur();
    }
    focus() {

        return this.input.focus();
    }

    value() {

        return this.input.value;
    }

    clear() {
        this.input.value = '';
        if (this.props.textCleared) {
            this.props.textCleared();
        }
        /*
        const ke = new KeyboardEvent("keydown", {
            bubbles: true, cancelable: true, keyCode: 13
        });
        document.body.dispatchEvent(ke);
        */

    }

    onBlur(event) {

        const relatedTarget = event.relatedTarget;
        if (relatedTarget) {
            const parent = relatedTarget.parentNode;
            let grandParent;

            if ( parent ) {
                grandParent = parent.parentNode;
            }

            if ( grandParent === this.dropdown) {
                return;//we don't set focus state, here
                //they clicked on a link
            }

        }

        this.setState({
            focused: false
        }, () => {
            if (this.props.onBlur ) {
                this.props.onBlur(event);
            }
        });
        /*
        //have to let this get behind the stack as otherwise it will remove the link
        //we clicked on and its event won't fire
        setTimeout(() => {

            this.setState({
                focused: false
            });
        }, 1000);
        */
    }
    onFocus() {
        this.setState({
            focused: true
        });
    }

    dropDownClick(v){

        this.props.autoCompleted(v);
        //we undo focus state here so this link could live and receive click
        this.setState({
            focused: false
        });
    }

    onKeyDown(event) {
        const { delaySaveTimeInMS, onChange } = this.props;
        if (!delaySaveTimeInMS) return;

        if (this.onChangeTimeout) {
            clearTimeout(this.onChangeTimeout);
        }

        // Prevent react from resetting its properties
        event.persist();

        this.onChangeTimeout = setTimeout(() => onChange(event, false), delaySaveTimeInMS);      
    }

    render() {
        const { maxLength, value } = this.props;
        let remainingCharacters = maxLength;
        if (typeof maxLength !== 'undefined') {
            remainingCharacters = value.length >= maxLength ? 0 : maxLength - value.length;
        }

        const inputClasses = ClassNames(ObjectAssign({
            'form-control': true,
            'form-control-field': true,
            'has-value': this.props.value && this.props.value !== ''
        }, this.props.inputClasses));

        // const value = this.props.value;
        const showClear = this.props.clearx && value;

        let autoCompleteValues = [];
        if ( this.props.autoValues) {

            autoCompleteValues = this.props.autoValues.map((v, i) => {
                return (
                    <li key={i} className='list-group-item'><a href="#" onClick={this.dropDownClick.bind(this, v)}
                        className='blue'>{v}</a></li>
                );
            });
        }

        let same = false;
        if ( this.props.autoValues && this.props.autoValues.length === 1 && this.props.autoValues[0] === value) {
            same = true;
        }

        //console.log('same', same);

        const showAutocomplete = this.state.focused && this.props.autoValues && !same && (value && value.length > 0 );
        return (
            <ControlGroup
                hasError={this.props.hasError}
                hideLabel={this.props.hideLabel}
                hideHelp={this.props.hideHelp}
                groupClasses={this.props.groupClasses}
                labelClasses={this.props.labelClasses}
                label={this.props.label}
                help={this.props.help}
                nodeTopRight={this.props.nodeTopRight}
            >
                { maxLength && (
                     <label className='text-muted' 
                         style={{ fontSize: 12, opacity: .8, position: 'absolute', top: -20, right: 0 }}
                    >{remainingCharacters} remaining characters</label>
                )}
                <input
                    id={this.props.id}
                    autoFocus={this.props.autoFocus}
                    ref={(c) => (this.input = c)}
                    type={this.props.type}
                    autoCapitalize={this.props.autoCapitalize}
                    readOnly={!!this.props.readOnly}
                    autoComplete={this.props.autoComplete}
                    className={inputClasses}
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    disabled={this.props.disabled ? 'disabled' : undefined}
                    defaultChecked={this.props.checked}
                    onChange={(event) => {
                        if (typeof this.props.onChange === 'function') {
                            this.props.onChange(event, undefined, !!this.props.delaySaveTimeInMS);
                        }                        
                    }} // added undefined for LinkState because it's expected second parameter to be undefined event
                    onKeyDown={this.onKeyDown.bind(this)}
                    onClick={this.props.onClick}
                    onBlur={this.onBlur.bind(this)}
                    onFocus={this.onFocus.bind(this)}
                    required={this.props.required}
                    maxLength={maxLength}
                />
                {showClear &&
                    (<span onClick={this.clear.bind(this)} className="searchclear"><i className='fa fa-times-circle'></i></span>)
                }
                <div className={`auto-complete ${(showAutocomplete ? '' : 'hidden') }`}>
                    <ul ref={(ref) => {
                        this.dropdown = ref;
                    }} className='list-group'>
                        {autoCompleteValues}
                    </ul>
                </div>
            </ControlGroup>
        );
    }
}

TextControl.propTypes = propTypes;
TextControl.defaultProps = defaultProps;


export default TextControl;
