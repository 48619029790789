'use strict';

import Actions from './actions';
import Button from '../../../components/form/button.jsx';
import ControlGroup from '../../../components/form/control-group.jsx';
import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/form/spinner.jsx';
import Store from './store';
import TextControl from '../../../components/form/text-control.jsx';
import PropTypes from 'prop-types';
import ObjectAssign from 'object-assign';
import Qs from 'qs';
import CrumbCheck from '../../../components/crumb-check.jsx';


const propTypes = {
    match: PropTypes.object
};

/*
We have a ready only field for the username/email so that browsers will pick it up when they store the password.  Otherwise
chrome, for example, will store a password with no username and then never suggest it if you have others.  Currently we
have the form submit and then another confirm you know your password dialog.  We could sign on right away now that we
have the browser capturing the username and password but to be safe we enter it again.  Also it proves the user knows it
if they didn't choose autofill.
Safari link
https://developer.apple.com/documentation/security/password_autofill/enabling_password_autofill_on_an_html_input_element
Things to remember.  Go into passwords and delete them for a given site.  Incognito mode doesn't save passwords.

Also of note.  If Safari detects when it thinks is click tracking it will disable cookies on the resulting page.  This
happened when send grid did click tracking with their domain on a Welcome email.  A very subtle problem.
Look up Safari Intelligent Tracking Protection
https://webkit.org/blog/8311/intelligent-tracking-prevention-2-0/
 */

class Confirm extends React.Component {
    constructor(props) {

        super(props);

        this.input = {};
        this.state = ObjectAssign({}, Store.getState(), { password: '', password_confirm: '' });
    }

    componentDidMount() {

        Actions.getDetails(this.props.match.params.id, this.props.match.params.key);

        this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));

        if (this.input.password) {
            this.input.password.focus();
        }
    }

    componentWillUnmount() {

        this.unsubscribeStore();
    }

    onStoreChange() {

        this.setState( ObjectAssign(this.state, Store.getState()));
    }

    handleSubmit(event) {

        event.preventDefault();
        event.stopPropagation();

        if ( this.input.password.value() !== this.input.password_confirm.value()) {
            const state = this.state;
            state.confirm.hasError.password = true;
            state.confirm.help.password = 'Passwords do not match';
            this.setState( state );
            return;
        }

        Actions.confirm({
            id: this.props.match.params.id,
            key: this.props.match.params.key,
            email: this.state.confirm.email,
            password: this.input.password.value()
        }, (err) => {
            if ( !err) {

            }
        });
    }

    checkPasswords(event) {


        const state = this.state;
        state[event.target.name] = event.target.value;
        if ( this.state.confirm.hasError.password ){
            //suppress until next click

            state.confirm.hasError.password = false;
            state.confirm.help.password = '';
        }

        this.setState( state );
    }

    render() {

        const alerts = [];
        const query = Qs.parse(location.search.substring(1));
        let returnUrl = '';
        if ( query.returnUrl ){
            returnUrl = '?returnUrl=' + query.returnUrl;
        }

        const login = '/login' + returnUrl;

        if (this.state.confirm.success) {
            alerts.push(<div key="success">
                <div className="alert alert-success">
                    <span className="btn">Congratulations!  Your account is setup.</span>
                    <Link to={login} className="btn btn-link">
                        Please click here to log in.
                    </Link>
                </div>
            </div>);
        }

        let formElements = '';

        if (this.state.confirm.error && this.state.confirm.hasError.key && !this.state.confirm.alreadySetup) {
            alerts.push(<div key="danger" className="alert alert-danger">
                {this.state.confirm.help.key}
            </div>);
        }
        else if ( this.state.confirm.error && !this.state.confirm.alreadySetup ) {
            alerts.push(<div key="danger" className="alert alert-danger">
                {this.state.confirm.error}
            </div>);
        }

        if ( this.state.confirm.loading || ( !this.state.confirm.hydrated && !this.state.confirm.success ) ){
            formElements = (
                <i className="fa fa-spinner"></i>
            );
        }
        else if (this.state.confirm.valid && !this.state.confirm.success) {
            formElements = <fieldset>
                <h4>Choose a password and we'll be all set.</h4>
                <TextControl
                    readOnly={true}
                    value={this.state.confirm.email}
                    name="username"
                    label="Email"
                    id="username"
                    type="email"
                    autoComplete="username email"
                    disabled={false}
                />
                <TextControl
                    ref={(c) => (this.input.password = c)}
                    value={this.state.password}
                    name="password"
                    label="New password"
                    type="password"
                    id="new-password-text-field"
                    autoComplete="new-password"
                    onChange={this.checkPasswords.bind(this)}
                    hasError={this.state.confirm.hasError.password}
                    help={this.state.confirm.help.password}
                    disabled={this.state.confirm.loading}
                />
                <TextControl
                    ref={(c) => (this.input.password_confirm = c)}
                    value={this.state.password_confirm}
                    name="password_confirm"
                    label="New password confirm"
                    type="password"
                    id="confirm-password-text-field"
                    autoComplete="new-password"
                    onChange={this.checkPasswords.bind(this)}
                    hasError={this.state.confirm.hasError.password_confirm}
                    help={this.state.confirm.help.password_confirm}
                    disabled={this.state.confirm.loading}
                />
                <h5>* Password must be at least 8 characters and include 1 character from 3 of the following: Upper case, lower case, numeric, non alphanumeric.</h5>
                <ControlGroup hideLabel={true} hideHelp={true}>
                    <Button
                        type="submit"
                        inputClasses={{ 'btn-primary': true }}
                        disabled={this.state.confirm.loading}>

                        Create password
                        <Spinner space="left" show={this.state.confirm.loading} />
                    </Button>
                </ControlGroup>
            </fieldset>;
        }
        else if (this.state.confirm.alreadySetup) {
            formElements = (
                <div>
                    <div>
                        <p>
                        This account has already been setup.
                        </p>
                        <p><Link to="/login" style={ { paddingLeft: 0 }} className="btn btn-link">Click here to login.</Link></p>
                        <p><Link to="/login/forgot" style={ { paddingLeft: 0 }} className="btn btn-link">Lost Password click here.</Link></p>
                    </div>
                </div>
            );
        }
        else if (this.state.confirm.expired) {
            formElements = (
                <div>
                    Contact your admin to send you another welcome email.
                </div>
            );
        }
        else {
        }


        return (
            <section>
                <h3 className="page-header">Welcome!</h3>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {alerts}
                    {formElements}
                </form>
                <CrumbCheck message={'Please click below to continue with the registration process.'}/>
            </section>
        );
    }
}

Confirm.propTypes = propTypes;


export default Confirm;
