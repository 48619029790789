'use strict';
import * as Redux from 'redux';
import Confirm from './reducers/confirm';


export default Redux.createStore(
    Redux.combineReducers({
        confirm: Confirm
    })
);
